import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Fade from "react-reveal/Fade"

const PrivacyPolicyToolKit = () => {
    return (
        <>
            <Layout>
                <Seo title="Privacy Policy" />
                <Fade top>
                    <div className="py-10 lg:py-12 bg-dusk"></div>
                </Fade>
                <div className="py-24">
                    <div className="">
                        <div className="container mx-auto text-dusk">
                            <div>
                                <div className="text-5xl font-firs-semibold">
                                    Privacy Policy
                                </div>
                                <div className="mt-4">
                                    This Privacy Policy is effective 1st January 2022.
                                </div>
                            </div>
                            <div className=" my-7 border-dusk border-t font py-10">
                                <div className="mb-4">
                                    This Digital Privacy Policy (“Privacy Policy”) describes how MAS Design Inc (“we” or “us” or “our”) collects, uses and discloses your personal data through a variety of digital means: when you are accessing or using this website or mobile application (the “Platform”) on any computer, mobile phone, tablet, console or other device (collectively, “Device”).
                                </div>
                                <div className="mb-4">
                                    We may modify this Privacy Policy at any time effective upon its posting.
                                </div>
                                <div className="mb-4">
                                    <span className="font-firs-medium">
                                        For European Economic Area ("EEA") data subjects:
                                    </span>
                                    We are required to set out the legal basis for collecting, using, disclosing, transferring or processing your personal data.  We have identified that legal basis in this Privacy Policy.  For EEA data subjects, we have also explained where appropriate the tests we have applied in assessing that legal basis (such as a "balancing test").  More information on the balancing test is available upon request.  The end of this Privacy Policy contains further content specific to EEA data subjects.
                                </div>
                                <div className="mb-4 pt-5">
                                    <div className="font-firs-semibold mb-3 ">1.	WHAT PERSONAL DATA WE COLLECT AND WHY AND LEGAL BASIS</div>
                                    <div className="mb-4">
                                        We collect personal data in the following ways:
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="font-firs-semibold mb-3">1.	Active collection</div>
                                    <div className="mb-4">
                                        Active collection means the personal data you provide to us when you share your fulfillment details with us. We may ask you to provide us with your name, your surname, your email address and other limited amounts of personal data. We process such personal data for the purpose of providing our services to you and to communicate with you regarding the services. Providing such personal data is voluntary. However, without providing such personal data, you will not be able to place an order. We will further use this personal data to optimize our products, learn more about our customers, including to build a database of interested consumers who can help us finalize and customize our products, and identify potential early adopters.
                                    </div>
                                    <div className="mb-4">
                                        We do not collect any credit card or other payment card data directly from you via the Platform.  Instead, we have arrangements with a number of third-party payment gateway providers to process your payments on our behalf – Paypal secure payment.  Any card data you provide through interacting with the Platform is submitted to the payment gateway provider to make your payment through.
                                    </div>
                                    <div className="mb-4">
                                        <div className="">
                                            <span className="font-firs-medium">
                                                For EEA data subjects:
                                            </span>
                                            The legal basis for the processing of the above personal data relating to active collection in some cases will be the contract on the use of the Platform concluded with you. The legal basis for the processing of the above personal data in some cases will be our legitimate interest, which is the use of personal data to develop our products and our business. We have assessed in a balancing test that the processing is necessary to achieve our aforementioned legitimate interests and that our legitimate interests are not overridden by your interests or your fundamental rights and freedoms.
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        We will also use your personal data to send you emails and other marketing messages about our products unless you have opted out of these options. The personal data we collect allows us to keep you posted on our latest development announcements. You can opt out of these communications by sharing a mail to softmatter@masholdings.com
                                    </div>
                                    <div className="mb-4">
                                        <div className="">
                                            <span className="font-firs-medium">
                                                For EEA data subjects:
                                            </span>
                                            We will only send you marketing messages when you have consented to receive them.  The legal basis for the processing of such personal data is consent.
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4 pt-5">
                                    <div className="font-firs-semibold mb-3 ">1. Passive collection</div>
                                    <div className="mb-4">
                                        Passive collection means : information, which may include personal data, which is automatically collected as you navigate through and interact with the content on the Platform, as well as install information on your Device (such as cookies).
                                    </div>
                                    <div className="mb-4">
                                        The purpose of passively collecting your information is to understand the visitors’ behavior and engagement to different areas of the Platform, along with to improve the overall customer experience.
                                    </div>
                                    <div className="mb-4">
                                        Through the tracking code embedded in our Platform, the information, which may include personal data, collected includes:
                                    </div>
                                </div>
                                <div className="mb-4 pt-5">
                                    <div className="font-firs-semibold mb-3 ">1. Device-specific data</div>
                                    <div className="mb-4">
                                        The following information may be collected through your Device and browser:
                                    </div>
                                </div>
                                <div>
                                    <ul className="pl-10 list-disc">
                                        <li className="mb-4">
                                            your Device's IP address (collected and stored in an anonymized format);
                                        </li>
                                        <li className="mb-4">
                                            device screen size;
                                        </li>
                                        <li className="mb-4">
                                            device type (unique device identifiers) and browser information;
                                        </li>
                                        <li className="mb-4">
                                            geographic location (country location only);
                                        </li>
                                        <li className="mb-4">
                                            preferred language used to display the webpage.
                                        </li>

                                    </ul>
                                </div>
                                <div className="mb-4 pt-5">
                                    <div className="font-firs-semibold mb-3 ">1.	Log data</div>
                                    <div className="mb-4">
                                        Our servers automatically record information that includes:
                                    </div>
                                </div>
                                <div>
                                    <ul className="pl-10 list-disc">
                                        <li className="mb-4">
                                            referring domain;
                                        </li>
                                        <li className="mb-4">
                                            pages visited;
                                        </li>
                                        <li className="mb-4">
                                            geographic location;
                                        </li>
                                        <li className="mb-4">
                                            preferred language used to display the webpage;
                                        </li>
                                        <li className="mb-4">
                                            date and time when Platform pages were accessed;
                                        </li>


                                    </ul>
                                </div>
                                <div className="mb-4 pt-5">
                                    <div className="font-firs-semibold mb-3 ">1. Cookies</div>
                                </div>
                                <div className="mb-4">
                                    We do not currently respond to "do not track" signals in
                                    browsers; we are trying to provide a customized experience.
                                    The Platform uses "cookies" to collect information, which
                                    may include personal data including standard internet log
                                    information and details of your behavioral patterns upon
                                    visiting our Platform. We do this to provide you with a
                                    better experience, identify preferences, diagnose technical
                                    problems, analyze trends and to improve the Platform.
                                    "Cookies" are small data files transferred onto computers or
                                    devices by websites for record-keeping purposes and to
                                    enhance functionality on the Platform.
                                </div>
                                <div className="mb-4">
                                    <span className="font-firs-medium">
                                        For EEA data subjects:
                                    </span>
                                    The legal basis for the automatic collection of personal
                                    data listed above are our legitimate interests which are the
                                    following: to monitor and maintain the performance of the
                                    Platform and to analyze trends, usage and activities in
                                    connection with our Platform, to provide a better experience
                                    for you, to identify preferences, to diagnose technical
                                    problems and to analyze trends and to improve the Platform.
                                    We have assessed in a balancing test that the processing is
                                    necessary to achieve our aforementioned legitimate interests
                                    and that our legitimate interests are not overridden by your
                                    interests or your fundamental rights and freedoms. Providing
                                    such personal data is voluntary.
                                </div>
                                <div className="mb-4">
                                    The Platform also uses cookies to collect information to be
                                    used for targeted marketing purposes and/or tracking
                                    purposes.  If the law requires us to do so, we will first
                                    seek your consent before using cookies in this way. For EEA
                                    data subjects:  We will only use cookies in this way with
                                    your consent.  The legal basis for such processing is your
                                    consent.
                                </div>
                                <div className="mb-4">
                                    Information of Data subject will not be shared with any
                                    third party suppliers
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium mb-4">
                                    <li>Third Party Services</li>
                                </ul>
                                <div className="mb-4">
                                    We use a variety of services hosted by third parties, such
                                    as Google Analytics, Adwords and OneTrust. The following
                                    table lists the cookies that we use on our Platform:
                                </div>
                                <div className="mb-4 pt-5">
                                    <div className="md:overflow-auto overflow-x-scroll">
                                        <div className=" md:w-full w-[1000px] grid grid-cols-7 mb-8 break-words">
                                            <div className="p-2 border font-firs-medium">Name</div>
                                            <div className="p-2 border font-firs-medium border-l-[0px]">
                                                File(s)
                                            </div>
                                            <div className="p-2 border font-firs-medium border-l-[0px]">
                                                Lifespan
                                            </div>
                                            <div className="p-2 border font-firs-medium border-l-[0px]">
                                                Explanation
                                            </div>
                                            <div className="p-2 border font-firs-medium border-l-[0px]">
                                                Opt out
                                            </div>
                                            <div className="p-2 border font-firs-medium border-l-[0px]">
                                                Cookie Type
                                            </div>
                                            <div className="p-2 border font-firs-medium border-l-[0px]">
                                                Recipient and appropriate safeguards
                                            </div>
                                            <div className="p-2 border border-t-[0px]">
                                                Google Analytics – Classic Analytics
                                            </div>
                                            <div className="p-2 border border-l-[0px]  border-t-[0px]">
                                                <div className="mb-2">__utma</div>
                                                <div className="mb-2">__utmb</div>
                                                <div className="mb-2">__utmc</div>
                                                <div className="mb-2">__utmt</div>
                                                <div className="mb-2">__utmz</div>
                                                <div className="mb-2">__unam</div>
                                            </div>
                                            <div className="p-2 border border-l-[0px]  border-t-[0px]">
                                                <div className="mb-2">2 years from set/update</div>

                                                <div className="mb-2">10 minutes</div>

                                                <div className="mb-2">30 mins from set/update</div>

                                                <div className="mb-2">End of browser session</div>

                                                <div className="mb-2">6 months from set/update</div>

                                                <div className="mb-2">2 years from set/update</div>
                                            </div>
                                            <div className="p-2 border border-l-[0px]  border-t-[0px]">
                                                <div className="mb-2">
                                                    This website uses Google Analytics, a web analytics
                                                    service provided by Google, Inc. (“Google”). Google
                                                    Analytics uses cookies, which are text files placed on
                                                    the user’s computer, to help the website analyze how
                                                    users use the site. The information generated by the
                                                    cookie about the use of the website by the users will
                                                    generally be transferred to and stored by Google on
                                                    servers in the United States. This website has
                                                    activated the IP-address anonymization functionality
                                                    so that the IP-address of the user located within the
                                                    member states of the EU or the EEA are shortened by
                                                    Google prior to such a transfer.
                                                </div>
                                                <div className="mb-2">
                                                    In exceptional cases only, the full IP-address may be
                                                    transferred to the servers of Google in the USA to be
                                                    shortened there. Google will process this information
                                                    on our behalf for purposes of analyzing the usage of
                                                    the website by the users, compiling reports on website
                                                    activities and providing other services relating to
                                                    the usage of the website and the internet vis-à-vis
                                                    the website operator. Google will not link your IP
                                                    address which is transferred by your browser via
                                                    Google Analytics with any other data held by Google.
                                                </div>
                                                <div className="mb-2">
                                                    You may refuse the use of cookies by selecting the
                                                    appropriate settings on your browser, however please
                                                    note that if you do this you may not be able to use
                                                    the full functionality of this website. You may
                                                    further opt-out of the collection of your data
                                                    (including your IP-address) generated by the Cookie
                                                    and relating to your usage of the website and the
                                                    processing of the data by Google by downloading and
                                                    installing the add-on for your current web browser
                                                    from the following link:
                                                    <a
                                                        href="  http://tools.google.com/dlpage/gaoptout?hl=de"
                                                        className="text-mint"
                                                    >
                                                        http://tools.google.com/dlpage/gaoptout?hl=de
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="p-2 border border-l-[0px]  border-t-[0px]">
                                                You may further opt-out of the collection of your data
                                                (including your IP-address) generated by the Cookie and
                                                relating to your usage of the website and the processing
                                                of the data by Google by downloading and installing the
                                                add-on for your current web browser from the following
                                                link:
                                                <a
                                                    href="http://tools.google.com/dlpage/gaoptout?hl=de"
                                                    className="text-mint"
                                                >
                                                    http://tools.google.com/dlpage/gaoptout?hl=de
                                                </a>
                                            </div>
                                            <div className="p-2 border border-l-[0px]  border-t-[0px]">
                                                <div className="mb-2">first party cookie</div>
                                                <div>
                                                    functionality cookies, targeting or advertising
                                                    cookies
                                                </div>
                                            </div>
                                            <div className="p-2 border border-l-[0px]  border-t-[0px]">
                                                <div className="font-firs-medium mb-2">
                                                    EU Privacy Shield
                                                </div>
                                                <div>
                                                    The U.S. Department of Commerce has approved Google's
                                                    certification to the Privacy Shield as fully
                                                    compliant. View our Privacy Shield certification.
                                                </div>
                                                <div className="font-firs-medium my-2">ISO 27001</div>
                                                <div>
                                                    Google has earned ISO 27001 certification for the
                                                    systems, applications, people, technology, processes,
                                                    and data centers serving a number of Google products,
                                                    including Google Analytics. Download our certificate
                                                    here (PDF) or learn more about ISO 27001.
                                                </div>
                                                <div className="font-firs-medium my-2">
                                                    Information security
                                                </div>
                                                <div className="mb-2">
                                                    In web-based computing, security of both data and
                                                    applications is critical. Google dedicates significant
                                                    resources towards securing applications and data
                                                    handling to prevent unauthorized access to data.
                                                </div>
                                                <div className="mb-2">
                                                    Data is stored in an encoded format optimized for
                                                    performance, rather than stored in a traditional file
                                                    system or database manner. Data is dispersed across a
                                                    number of physical and logical volumes for redundancy
                                                    and expedient access, thereby obfuscating it from
                                                    tampering.
                                                </div>
                                                <div className="mb-2">
                                                    Google applications run in a multi-tenant, distributed
                                                    environment. Rather than segregating each customer's
                                                    data onto a single machine or set of machines, data
                                                    from all Google users (consumers, business, and even
                                                    Google's own data) is distributed among a shared
                                                    infrastructure composed of Google's many homogeneous
                                                    machines and located in Google's data centers.
                                                </div>
                                                <div className="mb-2">
                                                    In addition, Google Analytics ensures secure
                                                    transmission of its JavaScript libraries and
                                                    measurement data. Google Analytics by default uses
                                                    HTTP Strict Transport Security (HSTS), which instructs
                                                    browsers that support HTTP over SSL (HTTPS) to use
                                                    that encryption protocol for all communication between
                                                    end users, websites, and Google Analytics servers.
                                                </div>
                                                <div className="mb-2">
                                                    <a
                                                        href="https://support.google.com/analytics/answer/6004245"
                                                        className="text-mint"
                                                    >
                                                        https://support.google.com/analytics/answer/6004245
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="p-2 border border-t-[0px]">
                                                Google Analytics - Universal Analytics
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                _ga
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                2 years from set/update
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                <div className="mb-2">
                                                    This website uses Google Analytics, a web analytics
                                                    service provided by Google, Inc. (“Google”). Google
                                                    Analytics uses cookies, which are text files placed on
                                                    the user’s computer, to help the website analyze how
                                                    users use the site. The information generated by the
                                                    cookie about the use of the website by the users will
                                                    generally be transferred to and stored by Google on
                                                    servers in the United States. This website has
                                                    activated the IP-address anonymization functionality
                                                    so that the IP-address of the user located within the
                                                    member states of the EU or the EEA are shortened by
                                                    Google prior to such a transfer.
                                                </div>
                                                <div className="mb-2">
                                                    In exceptional cases only, the full IP-address may be
                                                    transferred to the servers of Google in the USA to be
                                                    shortened there. Google will process this information
                                                    on our behalf for purposes of analyzing the usage of
                                                    the website by the users, compiling reports on website
                                                    activities and providing other services relating to
                                                    the usage of the website and the internet vis-à-vis
                                                    the website operator. Google will not link your IP
                                                    address which is transferred by your browser via
                                                    Google Analytics with any other data held by Google.
                                                </div>
                                                <div className="mb-2">
                                                    You may refuse the use of cookies by selecting the
                                                    appropriate settings on your browser, however please
                                                    note that if you do this you may not be able to use
                                                    the full functionality of this website. You may
                                                    further opt-out of the collection of your data
                                                    (including your IP-address) generated by the Cookie
                                                    and relating to your usage of the website and the
                                                    processing of the data by Google by downloading and
                                                    installing the add-on for your current web browser
                                                    from the following link:
                                                    <a
                                                        href="http://tools.google.com/dlpage/gaoptout?hl=de"
                                                        className="text-mint"
                                                    >
                                                        http://tools.google.com/dlpage/gaoptout?hl=de
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                You may further opt-out of the collection of your data
                                                (including your IP-address) generated by the Cookie and
                                                relating to your usage of the website and the processing
                                                of the data by Google by downloading and installing the
                                                add-on for your current web browser from the following
                                                link:
                                                <a
                                                    href="http://tools.google.com/dlpage/gaoptout?hl=de"
                                                    className="text-mint"
                                                >
                                                    http://tools.google.com/dlpage/gaoptout?hl=de
                                                </a>
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                first party cookie
                                            </div>
                                            <div className="p-2 border border-l-[0px]  border-t-[0px]">
                                                <div className="font-firs-medium mb-2">
                                                    EU Privacy Shield
                                                </div>
                                                <div>
                                                    The U.S. Department of Commerce has approved Google's
                                                    certification to the Privacy Shield as fully
                                                    compliant. View our Privacy Shield certification.
                                                </div>
                                                <div className="font-firs-medium my-2">ISO 27001</div>
                                                <div>
                                                    Google has earned ISO 27001 certification for the
                                                    systems, applications, people, technology, processes,
                                                    and data centers serving a number of Google products,
                                                    including Google Analytics. Download our certificate
                                                    here (PDF) or learn more about ISO 27001.
                                                </div>
                                                <div className="font-firs-medium my-2">
                                                    Information security
                                                </div>
                                                <div className="mb-2">
                                                    In web-based computing, security of both data and
                                                    applications is critical. Google dedicates significant
                                                    resources towards securing applications and data
                                                    handling to prevent unauthorized access to data.
                                                </div>
                                                <div className="mb-2">
                                                    Data is stored in an encoded format optimized for
                                                    performance, rather than stored in a traditional file
                                                    system or database manner. Data is dispersed across a
                                                    number of physical and logical volumes for redundancy
                                                    and expedient access, thereby obfuscating it from
                                                    tampering.
                                                </div>
                                                <div className="mb-2">
                                                    Google applications run in a multi-tenant, distributed
                                                    environment. Rather than segregating each customer's
                                                    data onto a single machine or set of machines, data
                                                    from all Google users (consumers, business, and even
                                                    Google's own data) is distributed among a shared
                                                    infrastructure composed of Google's many homogeneous
                                                    machines and located in Google's data centers.
                                                </div>
                                                <div className="mb-2">
                                                    In addition, Google Analytics ensures secure
                                                    transmission of its JavaScript libraries and
                                                    measurement data. Google Analytics by default uses
                                                    HTTP Strict Transport Security (HSTS), which instructs
                                                    browsers that support HTTP over SSL (HTTPS) to use
                                                    that encryption protocol for all communication between
                                                    end users, websites, and Google Analytics servers.
                                                </div>
                                                <div className="mb-2">
                                                    <a
                                                        href="https://support.google.com/analytics/answer/6004245"
                                                        className="text-mint"
                                                    >
                                                        https://support.google.com/analytics/answer/6004245
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="p-2  border border-t-[0px]">Hotjar</div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                <div className="mb-2">_hjClosedSurveyInvites</div>
                                                <div className="mb-2">_hjDonePolls</div>
                                                <div className="mb-2">_hjMinimizedPolls</div>
                                                <div className="mb-2">_hjDoneTestersWidgets</div>
                                                <div className="mb-2">_hjMinimizedTestersWidgets</div>
                                                <div className="mb-2">_hjIncludedInSample</div>
                                                <div className="mb-2">_hjShownFeedbackMessage</div>
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                365 days
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                When someone visits hotjar.com, we collect standard
                                                internet log information and details of visitor
                                                behaviour patterns. We do this to find out things such
                                                as the number of visitors to the various parts of the
                                                site. We collect this information in a way which does
                                                not personally identify anyone.
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                All Cookies will automatically delete after the
                                                retention  time period.
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                first party cookie, third party cookie
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                <div className="mb-2">
                                                    All data Hotjar collects is stored electronically in
                                                    Ireland, Europe on the Amazon Web Services
                                                    infrastructure, eu-west-1 datacenter. Hotjar
                                                    application servers and database servers run inside an
                                                    Amazon VPC, Virtual Private Cloud. The database
                                                    containing visitor and usage data is only accessible
                                                    from the application servers and no outside sources
                                                    are allowed to connect to the database. Our data
                                                    retention times are no longer than 365 days.
                                                </div>
                                                <div className="mb-2">
                                                    Site visitors are assigned a unique user identifier,
                                                    UUID, so that Hotjar can keep track of returning
                                                    visitors without relying on any personal information,
                                                    such as the IP address.
                                                </div>
                                                <div className="mb-2">
                                                    IP addresses of visitors are always suppressed before
                                                    being stored. We set the last octet of IPv4 addresses,
                                                    all connections to Hotjar are made via IPv4, to 0 to
                                                    ensure the full IP address is never written to disk.
                                                    For example, if a visitor's IP address is 1.2.3.4, it
                                                    will be stored as 1.2.3.0. The first three octets of
                                                    the IP address are only used to determine the
                                                    geographic location of the visitor.
                                                </div>
                                                <div className="mb-2">
                                                    When collecting data with Recordings, Hotjar
                                                    automatically suppresses keystroke data on password
                                                    fields, as well as when we detect a credit card number
                                                    being entered into an input field. We also offer the
                                                    ability to manually suppress data on specific input
                                                    fields or on-page text containing email
                                                    addresses/numbers. In both cases, the data is
                                                    suppressed client-side, the visitor’s browser, which
                                                    means it never reaches our servers.
                                                </div>
                                            </div>
                                            <div className="p-2 border border-t-[0px]">
                                                Google AdWords
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                ads/ga-audiences
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                Session
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                <div className="mb-2">
                                                    Google AdWords (ads) are available in four main
                                                    categories, display, video, search, and app ads, and
                                                    are a great way to promote your business to
                                                    individuals using over two million websites and apps
                                                    including the Google search engine and Gmail. The most
                                                    popular are the search ads, which will list your
                                                    business in a prime position in the search engine
                                                    results listing for the keywords you select. Google
                                                    ads place you ahead of any listings that pop up in the
                                                    Google search results listings.
                                                </div>
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                All Cookies will automatically delete after the
                                                retention time period.
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                first party cookie, third party cookie
                                            </div>
                                            <div className="p-2 border border-l-[0px]  border-t-[0px]">
                                                <div className="font-firs-medium mb-2">
                                                    EU Privacy Shield
                                                </div>
                                                <div>
                                                    The U.S. Department of Commerce has approved Google's
                                                    certification to the Privacy Shield as fully
                                                    compliant. View our Privacy Shield certification.
                                                </div>
                                                <div className="font-firs-medium my-2">ISO 27001</div>
                                                <div>
                                                    Google has earned ISO 27001 certification for the
                                                    systems, applications, people, technology, processes,
                                                    and data centers serving a number of Google products,
                                                    including Google Analytics. Download our certificate
                                                    here (PDF) or learn more about ISO 27001.
                                                </div>
                                                <div className="font-firs-medium my-2">
                                                    Information security
                                                </div>
                                                <div className="mb-2">
                                                    In web-based computing, security of both data and
                                                    applications is critical. Google dedicates significant
                                                    resources towards securing applications and data
                                                    handling to prevent unauthorized access to data.
                                                </div>
                                                <div className="mb-2">
                                                    Data is stored in an encoded format optimized for
                                                    performance, rather than stored in a traditional file
                                                    system or database manner. Data is dispersed across a
                                                    number of physical and logical volumes for redundancy
                                                    and expedient access, thereby obfuscating it from
                                                    tampering.
                                                </div>
                                                <div className="mb-2">
                                                    Google applications run in a multi-tenant, distributed
                                                    environment. Rather than segregating each customer's
                                                    data onto a single machine or set of machines, data
                                                    from all Google users (consumers, business, and even
                                                    Google's own data) is distributed among a shared
                                                    infrastructure composed of Google's many homogeneous
                                                    machines and located in Google's data centers.
                                                </div>
                                                <div className="mb-2">
                                                    <a
                                                        href=" https://support.google.com/analytics/answer/6004245"
                                                        className="text-mint"
                                                    >
                                                        https://support.google.com/analytics/answer/6004245
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="p-2 border border-t-[0px]">Facebook</div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                <div className="mb-2">lu</div>
                                                <div className="mb-2">xs</div>
                                                <div className="mb-2">c_user</div>
                                                <div className="mb-2">m_user</div>
                                                <div className="mb-2">pl</div>
                                                <div className="mb-2">dblc</div>
                                                <div className="mb-2">aks</div>
                                                <div className="mb-2">aksb</div>
                                                <div className="mb-2">sfau</div>
                                                <div className="mb-2">ick</div>
                                                <div className="mb-2">s</div>
                                                <div className="mb-2">datr</div>
                                                <div className="mb-2">sb</div>
                                                <div className="mb-2">fr</div>
                                                <div className="mb-2">oo</div>
                                                <div className="mb-2">ddid</div>
                                                <div className="mb-2">_fbc</div>
                                                <div className="mb-2">_fbp</div>
                                                <div className="mb-2">dpr</div>
                                                <div className="mb-2">gu</div>
                                                <div className="mb-2">lh</div>
                                                <div className="mb-2">locale</div>
                                                <div className="mb-2">sb</div>
                                                <div className="mb-2">shopping_cart</div>
                                                <div className="mb-2">wd</div>
                                                <div className="mb-2">js_ver</div>
                                                <div className="mb-2">rc</div>
                                                <div className="mb-2">campaign_click_url</div>
                                            </div>
                                            <div className="p-2  border-l-[0px] border border-t-[0px]">
                                                <div className="mb-2">2 years</div>
                                                <div className="mb-2">90 days</div>
                                                <div className="mb-2">90 days</div>
                                                <div className="mb-2">90 days</div>
                                                <div className="mb-2">90 days</div>
                                                <div className="mb-2">2 years</div>
                                                <div className="mb-2">30 days</div>
                                                <div className="mb-2">30 minutes</div>
                                                <div className="mb-2">1day</div>
                                                <div className="mb-2">2 years</div>
                                                <div className="mb-2">90 days</div>
                                                <div className="mb-2">2 years</div>
                                                <div className="mb-2">2 years</div>
                                                <div className="mb-2">90 days</div>
                                                <div className="mb-2">5 years</div>
                                                <div className="mb-2">28 days</div>
                                                <div className="mb-2">90 days</div>
                                                <div className="mb-2">90 days</div>
                                                <div className="mb-2">7 days</div>
                                                <div className="mb-2">Session</div>
                                                <div className="mb-2">7 days</div>
                                                <div className="mb-2">7 days</div>
                                                <div className="mb-2">2 years</div>
                                                <div className="mb-2">Session</div>
                                                <div className="mb-2">7 days</div>
                                                <div className="mb-2">7 days</div>
                                                <div className="mb-2">7 days</div>
                                                <div className="mb-2">30 days</div>
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                Cookies are small pieces of text used to store
                                                information on web browsers. Cookies are used to store
                                                and receive identifiers and other information on
                                                computers, phones, and other devices. Other
                                                technologies, including data we store on your web
                                                browser or device, identifiers associated with your
                                                device, and other software, are used for similar
                                                purposes. We use cookies if you have a Facebook account,
                                                use the Facebook Products, including our website and
                                                apps, or visit other websites and apps that use the
                                                Facebook Products (including the Like button or other
                                                Facebook Technologies). Cookies enable Facebook to offer
                                                the Facebook Products to you and to understand the
                                                information we receive about you, including information
                                                about your use of other websites and apps, whether or
                                                not you are registered or logged in.
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                All Cookies will automatically delete after the
                                                retention  time period. Depending on the type it will
                                                delete on user session log out, session time out or when
                                                you close the browser.
                                            </div>
                                            <div className="p-2 border-l-[0px] border border-t-[0px]">
                                                first party cookie, third party cookie,targeting or
                                                advertising cookies,functionality cookies, performance
                                                cookies
                                            </div>
                                            <div className="p-2  border-l-[0px] border border-t-[0px]">
                                                <div className="mb-2">
                                                    Facebook does not share User confidential data (User
                                                    credentials, Bank details and etc) with 3rd party .
                                                </div>
                                                <div className="mb-2">
                                                    Facebook is  ISO 27001 certified Organization.
                                                </div>
                                                <div className="mb-2">
                                                    Facebook uses latest security mechanisms to safeguard
                                                    their environment, which help end users to safeguard
                                                    their user data.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">
                                        WHAT WE COLLECT FROM OTHER INTERACTIONS WE HAVE WITH YOU
                                        AND FROM THIRD PARTIES
                                    </li>
                                </ul>
                                <div className="mb-4">
                                    To the extent you have consented to us doing so, we may
                                    combine personal data you give us with other personal data
                                    we hold about you from other sources, transactions and
                                    communications. This may include personal data obtained from
                                    our stores, direct mail, catalogs, events, products and
                                    applications, or other interactions. To the extent you have
                                    consented, we may also combine that personal data with data
                                    that is publicly available and data from third parties. We
                                    also collect personal data about gift recipients provided by
                                    the giver.
                                </div>
                                <div className="mb-4">
                                    <span className="font-firs-medium">
                                        For EEA data subjects:
                                    </span>
                                    The legal basis for the respective transfer of your personal
                                    data is our legitimate interests. Our legitimate interests
                                    are the transmission of personal data within the group of
                                    companies for internal administrative and support purposes.
                                    We have assessed in a balancing test that the processing is
                                    necessary to achieve our aforementioned legitimate interests
                                    and that our legitimate interests are not overridden by your
                                    interests or your fundamental rights and freedoms.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">SERVICE PROVIDERS</li>
                                </ul>
                                <div className="mb-4">
                                    We may transfer your personal data to service providers to
                                    conduct our business. For example, they may handle data
                                    management, email distribution, market research, information
                                    analysis, and promotions management, acting as data
                                    processor. We may also share your personal data to
                                    administer features (e.g. music download, race registration,
                                    or workout routine). Those external service providers will
                                    be subject to contractual obligations to implement
                                    appropriate technical and organizational security measures
                                    to safeguard your personal data and to process the personal
                                    data only as instructed.  All credit card or other payment
                                    card data you supply through the Platform is transmitted to
                                    the third party payment gateway provider you select.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">BY LAW OR TO PROTECT RIGHTS</li>
                                </ul>
                                <div className="mb-4">
                                    To the extent permitted or required by applicable law we may
                                    disclose personal data upon governmental request, in
                                    response to a court order, when required by law, to enforce
                                    our policies, or to protect our or others’ rights, property
                                    or safety. To the extent permitted by law we may share
                                    personal data to prevent illegal uses of our products and
                                    services or violations of the Terms of Use, or to defend
                                    ourselves against third-party claims. We may also share
                                    personal data with companies assisting in fraud protection
                                    or investigation.
                                </div>
                                <div className="mb-4">
                                    <span className="font-firs-medium">
                                        For EEA data subjects:
                                    </span>
                                    The legal basis for such processing is compliance with a
                                    legal obligation to which we are subject or our legitimate
                                    interests, such as exercise or defense of legal claims. We
                                    have assessed in a balancing test that the processing is
                                    necessary to achieve our aforementioned legitimate interests
                                    and that our legitimate interests are not overridden by your
                                    interests or your fundamental rights and freedoms.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">BUSINESS TRANSFERS</li>
                                </ul>
                                <div className="mb-4">
                                    Your personal data may be transferred to a third party as a
                                    part of our business assets in a sale of a part or all of
                                    our business. If this should happen, notice of the transfer
                                    will be provided by posting to the Platform or other form of
                                    communication.
                                </div>
                                <div className="mb-4">
                                    <span className="font-firs-medium">
                                        For EEA data subjects:
                                    </span>
                                    The legal basis for the transfer may be your consent, the
                                    performance of a contract, the pursuit of legitimate
                                    interests, or other applicable legal bases.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">SHARING - YOU CHOOSE</li>
                                </ul>
                                <div className="mb-4">
                                    You may choose to share certain personal data. In order to
                                    participate in certain features, you may have to adjust your
                                    privacy settings and share more personal data. You may also
                                    choose to share your activity on other platforms, such as
                                    Facebook and Twitter. Please read the privacy policies of
                                    those platforms, because your activity published on those
                                    platforms will no longer be governed by this Privacy Policy.
                                </div>
                                <div className="mb-4">
                                    <span className="font-firs-medium">
                                        For EEA data subjects:
                                    </span>
                                    The legal basis for sharing this personal data is your
                                    consent.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">ROTECTING THE PERSONAL DATA </li>
                                </ul>
                                <div className="mb-4">
                                    Security Measures: We use a variety of security measures,
                                    including encryption and authentication tools, to help
                                    protect your personal data.  All credit card or other
                                    payment card data you supply through the Platform is
                                    transmitted to the selected third party payment gateway
                                    provider on a on an SSL encrypted basis.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">CONTACT US </li>
                                </ul>
                                <div className="mb-4">
                                    If you have concerns or questions regarding this Privacy
                                    Policy, please contact us as follows:
                                </div>
                                <div className="mb-4">
                                    MAS Design Inc, 450 Fashion Ave, Suite # 707, New York, NY 10123,USA
                                </div>
                                <div className="font-firs-medium mb-4">
                                    GENERAL DATA PROTECTION REGULATION DISCLOSURES FOR EEA DATA
                                    SUBJECTS
                                </div>
                                <div className="font-firs-medium mb-4">
                                    The below sections set out rights and other matters specific
                                    to EEA data subjects.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">INTERNATIONAL DATA TRANSFER </li>
                                </ul>
                                <div className="mb-4">
                                    The personal data that we collect or receive about you may
                                    be transferred to and processed by recipients who are
                                    located inside or outside the European Economic Area and
                                    which do not provide for an adequate level of data
                                    protection. The countries that are recognized to provide for
                                    an adequate level of data protection from an EU law
                                    perspective are Andorra, Argentina, Canada, Switzerland,
                                    Faeroe Islands, Guernsey, the State of Israel, Isle of Man,
                                    Jersey, New Zealand and the Eastern Republic of Uruguay.
                                    Recipients in the US may partially be certified under the
                                    EU-U.S. Privacy Shield and thereby deemed to provide for an
                                    adequate level of data protection from an EU law
                                    perspective. To the extent your personal data is transferred
                                    to countries that do not provide for an adequate level of
                                    data protection from an EU law perspective, we will base the
                                    respective transfer on appropriate safeguards, such as
                                    standard data protection clauses adopted by the European
                                    Commission. You can ask for a copy of such appropriate
                                    safeguards by contacting us as set out in Section “CONTACT
                                    US”. The access is limited to recipients with a need to
                                    know.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">KEEPING YOUR PERSONAL DATA </li>
                                </ul>
                                <div className="mb-4">
                                    You can modify or delete your profile within certain of our
                                    services, through your account. Your information previously
                                    posted may still be publicly viewable. We may keep
                                    information and content in our backup files and archives.
                                    Your personal data will be retained as long as necessary to
                                    provide you with the services requested. When we no longer
                                    needs to use your personal data to comply with contractual
                                    or statutory obligations, we will remove it from our systems
                                    and records and/or take steps to properly anonymize it so
                                    that you can no longer be identified from it, unless we need
                                    to keep your personal data, including if we need to keep
                                    your personal data to comply with legal or regulatory
                                    obligations to which we are subject, e.g. statutory
                                    retention periods and usually contain retention periods, or
                                    if we need it to preserve evidence within the statutes of
                                    limitation.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">YOUR RIGHTS</li>
                                </ul>
                                <div className="mb-4">
                                    If you have declared your consent for any personal data
                                    processing activities, you can withdraw this consent at any
                                    time with future effect. Such a withdrawal will not affect
                                    the lawfulness of the processing prior to the consent
                                    withdrawal.
                                </div>
                                <div className="mb-4">
                                    Pursuant to applicable data protection law you may have the
                                    right to: request access to your personal data, request
                                    rectification of your personal data; request erasure of your
                                    personal data, request restriction of processing of your
                                    personal data; request data portability, and object to the
                                    processing of your personal data. Please note that these
                                    aforementioned rights might be limited under the applicable
                                    national data protection law. For further information on
                                    these rights, please refer to Section “YOUR RIGHTS IN
                                    DETAIL”.
                                </div>
                                <div className="mb-4">
                                    You also have the right to lodge a complaint with a data
                                    protection supervisory authority. To exercise your rights
                                    please contact us as stated in Section “CONTACT US”.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">YOUR RIGHTS IN DETAIL</li>
                                </ul>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">Right of access</li>
                                </ul>
                                <div className="mb-4">
                                    You may have the right to obtain from us confirmation as to
                                    whether or not personal data concerning you is processed,
                                    and, where that is the case, to request access to the
                                    personal data. The access information includes – inter alia
                                    – the purposes of the processing, the categories of personal
                                    data concerned, and the recipients or categories of
                                    recipients to whom the personal data have been or will be
                                    disclosed. However, this is not an absolute right and the
                                    interests of other individuals may restrict your right of
                                    access.
                                </div>
                                <div className="mb-4">
                                    You may have the right to obtain a copy of the personal data
                                    undergoing processing. For further copies requested by you,
                                    we may charge a reasonable fee based on administrative
                                    costs.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">Right to rectification</li>
                                </ul>
                                <div className="mb-4">
                                    You may have the right to obtain from us the rectification
                                    of inaccurate personal data concerning you. Depending on the
                                    purposes of the processing, you may have the right to have
                                    incomplete personal data completed, including by means of
                                    providing a supplementary statement.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">
                                        Right to erasure ("right to be forgotten")
                                    </li>
                                </ul>
                                <div className="mb-4">
                                    Under certain circumstances, you may have the right to
                                    obtain from us the erasure of personal data concerning you
                                    and we may be obliged to erase such personal data.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">Right to restriction of processing</li>
                                </ul>
                                <div className="mb-4">
                                    Under certain circumstances, you may have the right to
                                    obtain from us restriction of processing your personal data.
                                    In this case, the respective data will be marked and may
                                    only be processed by us for certain purposes.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">Right to data portability</li>
                                </ul>
                                <div className="mb-4">
                                    Under certain circumstances, you may have the right to
                                    receive the personal data concerning you, which you have
                                    provided to us, in a structured, commonly used and
                                    machine-readable format and you may have the right to
                                    transmit those data to another entity without hindrance from
                                    us.
                                </div>
                                <ul className="pl-10 list-disc font-firs-medium">
                                    <li className="mb-4">Right to object</li>
                                </ul>
                                <div className="mb-4 font-firs-medium">
                                    If the processing of your personal data is based on
                                    legitimate interests, you may have the right to object, on
                                    grounds relating to your particular situation, at any time
                                    to the processing of your personal data by us and we can be
                                    required to no longer process your personal data.
                                </div>
                                <div className="mb-4 font-firs-medium">
                                    Moreover, if your personal data is processed for direct
                                    marketing purposes, you have the right to object at any time
                                    to the processing of personal data concerning you for such
                                    marketing, which includes profiling to the extent that it is
                                    related to such direct marketing. In this case, your
                                    personal data will no longer be processed for such purposes
                                    by us.
                                </div>
                                {/* ---------------- */}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default PrivacyPolicyToolKit
